import React from 'react';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../../utils/resource-helper';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import Hero from '../../../components/Hero';
import SubfooterNav from '../../../components/SubfooterNav';
import Resource from '../../../components/Resource';
import bgImage from '../../../images/bg_biomed_texture.jpg';
import '../../../styles/pages/_unit-sections.scss';

const BiomedUnit3 = ({ data }) => {
  const heroBreadcrumbs = {
    unitLabel: 'UNIT 3',
    linkLabel: 'BIOMED COURSE',
    to: '/classroom-instruction/biomed',
  };

  const {
    bmNucleicAcidsProteinsIntro,
    bmCentralDogmaBiology,
    bmDnaModification,
    bmDnaIsolationAndPurification,
    bmProteinModification,
    bmProteinIsolationPurification,
    bmGeneticProtein,
    bmNucleicAcidAssays,
    bmProteinAssays,
    bmHowAreDrugsTested,
    bmMechanismsDrugDelivery,
    bmMysteryDiseaseConference,
  } = getKeyedResources(data);

  return (
    <Layout title="Biomed Unit 3">
      <Hero
        className={'hero--small course__hero'}
        modal
        imageSource="header-biomed-unit3@2x.jpg"
        guidSrc="0f7b4c29-5deb-4943-b262-8676b75686b9"
        posterSrc={'unit-3-biomed-intro-video-still.gif'}
        breadcrumbs={heroBreadcrumbs}
        color={'orange'}
        playtext={'Video Intro'}
      >
        <h1 className=" mb-0 pb-0">
          Nucleic Acids and Proteins: Disease Treatment Innovations
        </h1>
        <p className="pb-2">
          How can biotechnology help us develop and deliver pharmaceutical
          drugs?
        </p>
      </Hero>
      <Section className="unit-sections__at-a-glance-section pt-0 pb-0">
        <Container
          fullWidth
          className="unit-sections__at-a-glance-container pt-2 pb-1"
        >
          <div className="course__unit-indicator text-bold">
            <div>BIOMED | UNIT 3</div>
            <Link
              to="/classroom-instruction/biomed/unit-4"
              className="course__unit-indicator-link"
            >
              UNIT 4 <Icon marginLeft name="arrowright" />
            </Link>
          </div>
          <h4 className="mt-0.5 text-bold">At a Glance</h4>
          <Row>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-storyline.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                STORYLINE
              </h3>
              <ul className="course__list course__list-style-none">
                <li className="mr-3">
                  Explore the concept of diagnosing and treating diseases, then
                  innovate a solution using mechanisms of nucleic acids and
                  proteins.
                </li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-pacing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                PACING
              </h3>
              <ul className="course__list">
                <li>¼ School Year</li>
                <li>53 Days</li>
                <li>2 Flex Days Built In</li>
                <li>45-minute Classes</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-framing.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                FRAMING
              </h3>
              <ul className="course__list">
                <li>Standards Aligned</li>
                <li>Guiding Question</li>
                <li>Engineering Design Process</li>
                <li>Project Based</li>
              </ul>
            </Column>
            <Column medium={3} xsmall={6}>
              <Image filename="icon-lesson-plans-labs.svg" />
              <h3 className="text-bold pt-0 mt-0 course__list-heading">
                LESSON PLANS AND LABS
              </h3>
              <ul className="course__list">
                <li>9 Lessons with Lab Elements</li>
                <li>1 Core lab</li>
                <li>1 Final Project </li>
              </ul>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-0 pb-3"
      >
        <Container fullWidth className="pt-4 pb-1">
          <Row className="mb-2">
            <Column size={7}>
              <h3 className="text-bold">
                Lesson Plans, Labs and Student Capture Sheets
              </h3>
              <p>
                This unit includes lesson plans, labs, a student interactive and
                student capture sheets that empower educators to engage all
                students. This lesson bundle can be downloaded for use in the
                classroom.
              </p>
            </Column>
            {/* Full unit zip file download */}
            <Column offset={1} size={4}>
              <div className="unit_download">
                <a href="/units/Gen-BM-U3.zip" className="button">
                  <Icon marginRight name="download" />
                  Complete Unit
                </a>
                <span className="unit_download">ZIP, 20.5 MB</span>
              </div>
            </Column>
          </Row>
        </Container>
        <Container fullWidth>
          <Row>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmNucleicAcidsProteinsIntro.tophat}
                tophatClass={`${bmNucleicAcidsProteinsIntro.page}__resource-tophat`}
                ribbon={bmNucleicAcidsProteinsIntro.ribbon}
                ribbonClass={`${bmNucleicAcidsProteinsIntro.page}__resource-ribbon`}
                img=""
                duration={bmNucleicAcidsProteinsIntro.duration}
                subject={bmNucleicAcidsProteinsIntro.subject}
                subjectClass={`${bmNucleicAcidsProteinsIntro.page}__resource-subject`}
                title={bmNucleicAcidsProteinsIntro.title}
                description={bmNucleicAcidsProteinsIntro.description}
                actions={bmNucleicAcidsProteinsIntro.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmCentralDogmaBiology.tophat}
                tophatClass={`${bmCentralDogmaBiology.page}__resource-tophat`}
                ribbon={bmCentralDogmaBiology.ribbon}
                ribbonClass={`${bmCentralDogmaBiology.page}__resource-ribbon`}
                img=""
                duration={bmCentralDogmaBiology.duration}
                subject={bmCentralDogmaBiology.subject}
                subjectClass={`${bmCentralDogmaBiology.page}__resource-subject`}
                title={bmCentralDogmaBiology.title}
                description={bmCentralDogmaBiology.description}
                actions={bmCentralDogmaBiology.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmDnaModification.tophat}
                tophatClass={`${bmDnaModification.page}__resource-tophat`}
                ribbon={bmDnaModification.ribbon}
                ribbonClass={`${bmDnaModification.page}__resource-ribbon`}
                img=""
                duration={bmDnaModification.duration}
                subject={bmDnaModification.subject}
                subjectClass={`${bmDnaModification.page}__resource-subject`}
                title={bmDnaModification.title}
                description={bmDnaModification.description}
                actions={bmDnaModification.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmDnaIsolationAndPurification.tophat}
                tophatClass={`${bmDnaIsolationAndPurification.page}__resource-tophat`}
                ribbon={bmDnaIsolationAndPurification.ribbon}
                ribbonClass={`${bmDnaIsolationAndPurification.page}__resource-ribbon`}
                img=""
                duration={bmDnaIsolationAndPurification.duration}
                subject={bmDnaIsolationAndPurification.subject}
                subjectClass={`${bmDnaIsolationAndPurification.page}__resource-subject`}
                title={bmDnaIsolationAndPurification.title}
                description={bmDnaIsolationAndPurification.description}
                actions={bmDnaIsolationAndPurification.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmProteinModification.tophat}
                tophatClass={`${bmProteinModification.page}__resource-tophat`}
                ribbon={bmProteinModification.ribbon}
                ribbonClass={`${bmProteinModification.page}__resource-ribbon`}
                img=""
                duration={bmProteinModification.duration}
                subject={bmProteinModification.subject}
                subjectClass={`${bmProteinModification.page}__resource-subject`}
                title={bmProteinModification.title}
                description={bmProteinModification.description}
                actions={bmProteinModification.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmProteinIsolationPurification.tophat}
                tophatClass={`${bmProteinIsolationPurification.page}__resource-tophat`}
                ribbon={bmProteinIsolationPurification.ribbon}
                ribbonClass={`${bmProteinIsolationPurification.page}__resource-ribbon`}
                img=""
                duration={bmProteinIsolationPurification.duration}
                subject={bmProteinIsolationPurification.subject}
                subjectClass={`${bmProteinIsolationPurification.page}__resource-subject`}
                title={bmProteinIsolationPurification.title}
                description={bmProteinIsolationPurification.description}
                actions={bmProteinIsolationPurification.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmGeneticProtein.tophat}
                tophatClass={`${bmGeneticProtein.page}__resource-tophat`}
                ribbon={bmGeneticProtein.ribbon}
                ribbonClass={`${bmGeneticProtein.page}__resource-ribbon`}
                img=""
                duration={bmGeneticProtein.duration}
                subject={bmGeneticProtein.subject}
                subjectClass={`${bmGeneticProtein.page}__resource-subject`}
                title={bmGeneticProtein.title}
                description={bmGeneticProtein.description}
                actions={bmGeneticProtein.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmNucleicAcidAssays.tophat}
                tophatClass={`${bmNucleicAcidAssays.page}__resource-tophat`}
                ribbon={bmNucleicAcidAssays.ribbon}
                ribbonClass={`${bmNucleicAcidAssays.page}__resource-ribbon`}
                img=""
                duration={bmNucleicAcidAssays.duration}
                subject={bmNucleicAcidAssays.subject}
                subjectClass={`${bmNucleicAcidAssays.page}__resource-subject`}
                title={bmNucleicAcidAssays.title}
                description={bmNucleicAcidAssays.description}
                actions={bmNucleicAcidAssays.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmProteinAssays.tophat}
                tophatClass={`${bmProteinAssays.page}__resource-tophat`}
                ribbon={bmProteinAssays.ribbon}
                ribbonClass={`${bmProteinAssays.page}__resource-ribbon`}
                img=""
                duration={bmProteinAssays.duration}
                subject={bmProteinAssays.subject}
                subjectClass={`${bmProteinAssays.page}__resource-subject`}
                title={bmProteinAssays.title}
                description={bmProteinAssays.description}
                actions={bmProteinAssays.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmHowAreDrugsTested.tophat}
                tophatClass={`${bmHowAreDrugsTested.page}__resource-tophat`}
                ribbon={bmHowAreDrugsTested.ribbon}
                ribbonClass={`${bmHowAreDrugsTested.page}__resource-ribbon`}
                img=""
                duration={bmHowAreDrugsTested.duration}
                subject={bmHowAreDrugsTested.subject}
                subjectClass={`${bmHowAreDrugsTested.page}__resource-subject`}
                title={bmHowAreDrugsTested.title}
                description={bmHowAreDrugsTested.description}
                actions={bmHowAreDrugsTested.actions}
              />
            </Column>
            <Column medium={4} small={6} xsmall={12}>
              <Resource
                tophat={bmMechanismsDrugDelivery.tophat}
                tophatClass={`${bmMechanismsDrugDelivery.page}__resource-tophat`}
                ribbon={bmMechanismsDrugDelivery.ribbon}
                ribbonClass={`${bmMechanismsDrugDelivery.page}__resource-ribbon`}
                img=""
                duration={bmMechanismsDrugDelivery.duration}
                subject={bmMechanismsDrugDelivery.subject}
                subjectClass={`${bmMechanismsDrugDelivery.page}__resource-subject`}
                title={bmMechanismsDrugDelivery.title}
                description={bmMechanismsDrugDelivery.description}
                actions={bmMechanismsDrugDelivery.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Unit 3',
            link: '/classroom-instruction/biomed/unit-3',
          }}
          next={{
            name: 'Unit 4',
            link: '/classroom-instruction/biomed/unit-4',
          }}
          previous={{
            name: 'Unit 2',
            link: '/classroom-instruction/biomed/unit-2',
          }}
        />
      </Section>
    </Layout>
  );
};

export default BiomedUnit3;

export const query = graphql`
  query BiomedUnit3Resources {
    allResourcesJson(filter: { page: { eq: "biomed" }, unit: { eq: 3 } }) {
      edges {
        node {
          slug
          unit
          page
          ribbon
          tophat
          img
          subject
          title
          duration
          lab
          description
          actions {
            label
            type
            format
            size
            path
            title
            clickLabel
            clickType
            actions {
              label
              type
              format
              size
              path
              title
              clickLabel
              clickType
              guidSrc
              posterSrc
            }
          }
        }
      }
    }
  }
`;
